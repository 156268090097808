/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/lazyload@2.0.0-rc.2/lazyload.min.js
 * - /npm/bulma-extensions@6.2.7/dist/js/bulma-extensions.min.js
 * - /npm/bulma-toast@2.4.4/dist/bulma-toast.min.js
 * - /npm/linq@3.2.4/linq.min.js
 * - /npm/handsontable@15.2.0/dist/handsontable.full.min.js
 * - /npm/highcharts@12.1.2/highcharts.min.js
 * - /npm/moment@2.30.1/moment.min.js
 * - /npm/store@2.0.12/dist/store.legacy.min.js
 * - /npm/gasparesganga-jquery-loading-overlay@2.1.7/dist/loadingoverlay.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
